export default {
  install: (app, yup) => {
    app.config.globalProperties.$validator = {
      errors: {},
      validate(e, rules) {
        const schema = yup.object(
          Object.fromEntries([[e.target.name, eval(this.formatRules(rules))]])
        );
        try {
          schema.validateSync(
            Object.fromEntries([[e.target.name, e.target.value]])
          );
          delete this.errors[e.target.name];
        } catch (err) {
          this.errors[e.target.name] = err.errors;
        }
      },
      formatRules(rules) {
        let regs = "";
        if (!rules.includes(":")) {
          regs = rules.replaceAll("|", "().") + "()";
        }
        return "yup." + regs;
      },
      hasError(name) {
        return Object.keys(this.errors).includes(name);
      },
    };
  },
};
