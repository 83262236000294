import { createApp } from "vue";
import App from "./App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import auth from "@/helpers/Auth.service";
import helper from "@/helpers/index";
import notify from "@/helpers/notify";
import confirm from "@/helpers/confirm";
import validator from "@/helpers/validator";
import { trans } from "@/lang";
import { globalMixin } from "@/helpers/global-mixin.js";
import { encodeQuery, baseURL } from "@/helpers/utils.js";
import * as yup from "yup";

/**
 * For confirm example
 * this.$confirm({
        title: "test",
        content: "are you sure?",
        actionText: "Save",
        closeText: "Cancel",
        classes: "btn btn-primary",
        action: () => {
          // handle action
          console.log("Tested");
        },
      });
 */
const app = createApp(App);

let pendingRequests = 0;
axios.interceptors.request.use(
  (config) => {
    const company = localStorage.getItem("_company");
    if (company) {
      config.url = encodeQuery(config.url, {
        current_company: JSON.parse(company).id,
      });
    }
    pendingRequests++;
    store.state.isLoading = true;
    return config;
  },
  (error) => {
    pendingRequests--;
    store.commit("REQUEST_RESOLVED");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    pendingRequests--;
    store.commit("REQUEST_RESOLVED");
    return response;
  },
  (error) => {
    pendingRequests--;
    store.commit("REQUEST_RESOLVED");
    if (error.response.status == 401 && location.pathname != "/") {
      localStorage.removeItem("token");
      location.replace("/");
    }
    if (error.response.status == 500) {
      app.config.globalProperties.$notify({
        type: "error",
        message: "Error. Contact system administrator",
      });
    }
    return Promise.reject(error);
  }
);

app.config.globalProperties.$http = axios;
app.config.globalProperties.$http.defaults.baseURL = baseURL;
// Set onDownloadProgress globally
app.config.globalProperties.$http.defaults.onDownloadProgress = (progressEvent) => {
  if (progressEvent.total) {
    //const percentage = (progressEvent.loaded / progressEvent?.total || 1) * 100;
    const percentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    store.state.requestPercentage = percentage;
    if (percentage === 100) {
      setTimeout(() => {
        store.state.requestPercentage = 0;
      }, 400);
    }
  }
};

const token = localStorage.getItem("token");
if (token) {
  app.config.globalProperties.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}
app.mixin(globalMixin);
app
  .use(auth)
  .use(helper)
  .use(validator, yup)
  .use(notify, store)
  .use(confirm, store)
  .use(trans)
  .use(store)
  .use(router);
app.mount("#app");
