<template>
  <div id="layout-wrapper" v-if="$auth.active()" class="dark">
    <app-header></app-header>
    <navigation-bar></navigation-bar>
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div
            class="d-flex justify-content-center loader-container w-100"
            v-if="isFetching"
          >
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <router-view v-else />
  <NotificationAlert />
  <div class="d-none">
    <div id="order-preview">
      <OrderPreview
        :round="printableRound"
        :order="placedOrder"
        :items="roundItems"
      />
    </div>
  </div>
</template>
<script>
import AppHeader from "./shared/AppHeader.vue";
import NavigationBar from "./shared/NavigationBar.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";
import OrderPreview from "@/index/shared/OrderPreview.vue";
export default {
  name: "App",
  components: {
    AppHeader,
    NavigationBar,
    NotificationAlert,
    OrderPreview,
  },
  data: () => ({
    printableRound: {},
    roundItems: [],
    printInterval: null,
    isFetchingRounds: false,
    placedOrder: {},
    latestPrintedRoundId: null,
  }),
  computed: {
    isFetching() {
      return (
        this.$store.state.isLoading && this.$store.state.loadingTask == null
      );
    },
    roundsUrl() {
      return this.latestPrintedRoundId
        ? `next-printable-round?latest=${this.latestPrintedRoundId}`
        : `next-printable-round`;
    },
  },
  created() {
    if (this.$auth.active()) {
      this.$store.commit("SET_AJAX_TASK", "GETTING_CURRENT_USER");
      this.$http
        .get("auth/me")
        .then((response) => {
          this.$store.commit("SET_CURRENT_USER", response.data);
        })
    }
  },
  mounted() {
    this.$store.commit("SET_AJAX_TASK", "GET_APP_SETTINGS");
    this.$http.get("frontend/preloaders").then((response) => {
      localStorage.setItem("_tz", response.data.timezone);
      localStorage.setItem("today", response.data.today);
    });

    this.$nextTick(() => {
      const lastPrintedRound = window.localStorage.getItem(
        "__last_printed_round"
      );
      if (lastPrintedRound) {
        this.latestPrintedRoundId = lastPrintedRound;
      }
      if (
        navigator.userAgent.indexOf("Firefox") > 0 ||
        navigator.userAgent.toLowerCase().indexOf("firefox") !== -1
      ) {
        this.printInterval = setInterval(() => {
          if (!this.isFetchingRounds) {
            this.isFetchingRounds = true;
            this.$store.commit("SET_AJAX_TASK", "GETTING_LOCKED_TABLES");
            this.$http.get(this.roundsUrl).then((response) => {
              this.isFetchingRounds = false;
              if (response.data.status) {
                this.printableRound = response.data.round;
                this.roundItems = response.data.items;
                this.placedOrder = response.data.order;
                this.latestPrintedRoundId = this.printableRound.id;
                window.localStorage.setItem(
                  "__last_printed_round",
                  this.latestPrintedRoundId
                );
              }
            });
          }
        }, 3000);
      }
    });
  },
};
</script>
<style>
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: rgb(41, 171, 226);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 0%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px rgb(41, 171, 226), 0 0 5px rgb(41, 171, 226);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
</style>
