<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button
          class="btn btn-sm px-3 font-size-20 header-item vertical-menu-btn"
          type="button"
          @click="handleSideBar"
        >
          <i class="uil uil-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            @click="readNotifications"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell icon-sm"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span class="noti-dot bg-danger" v-if="hasNotifications"></span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown"
            data-popper-placement="bottom-end"
          >
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 font-size-15">
                    {{ $trans("Notifications") }}
                  </h5>
                </div>
              </div>
            </div>
            <div
              data-simplebar="init"
              style="max-height: 250px; min-height: auto; padding-bottom: 250px"
            >
              <div class="simplebar-wrapper" style="margin: 0px">
                <div class="simplebar-height-auto-observer-wrapper">
                  <div class="simplebar-height-auto-observer"></div>
                </div>

                <div class="simplebar-mask">
                  <div
                    class="simplebar-offset"
                    style="right: 0px; bottom: 0px; height: auto"
                  >
                    <div
                      class="simplebar-content-wrapper"
                      style="
                        height: auto;
                        padding-right: 0px;
                        padding-bottom: 0px;
                        overflow: scroll;
                      "
                    >
                      <div
                        class="simplebar-content"
                        style="
                          padding: 0px;
                          height: auto;
                          overflow: hidden scroll;
                        "
                      >
                        <h6 class="dropdown-header bg-light">New</h6>
                        <RouterLink
                          :to="`/reports/requisitions/${item.data.notification.slug}`"
                          class="text-reset notification-item"
                          v-for="(item, i) in notifications"
                          :key="'notification' + i"
                        >
                          <div class="d-flex border-bottom align-items-start">
                            <div class="flex-shrink-0">
                              <div class="avatar-sm me-3">
                                <span
                                  class="
                                    avatar-title
                                    bg-soft-success
                                    text-success
                                    rounded-circle
                                    font-size-16
                                  "
                                >
                                  <i class="uil-message"></i>
                                </span>
                              </div>
                            </div>
                            <div class="flex-grow-1">
                              <h6 class="mb-1">
                                {{ i }} - {{ item.data.notification.type }}
                              </h6>
                              <div class="text-muted">
                                <p
                                  class="mb-1 font-size-13"
                                  v-html="item.data.notification.message"
                                ></p>
                                <p
                                  class="
                                    mb-0
                                    font-size-10
                                    text-uppercase
                                    fw-bold
                                  "
                                >
                                  <i class="mdi mdi-clock-outline"></i>
                                  {{
                                    $helper.timeDifference(
                                      new Date(item.created_at)
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="simplebar-placeholder"
                  style="width: 0px; height: 56px"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="rounded-circle header-profile-user"
              src="/img/avatar.png"
              alt="Header Avatar"
            />
            <span class="ms-2 d-none d-sm-block user-item-desc">
              <span class="user-name">{{ user.name }}</span>
              <span class="user-sub-title">{{
                user?.role?.name || "Super Admin"
              }}</span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0" style="">
            <div class="p-3 bg-primary border-bottom">
              <h6 class="mb-0 text-white">{{ user.name }}</h6>
              <p class="mb-0 font-size-11 text-white-50 fw-semibold">
                {{ user.email }}
              </p>
            </div>
            <RouterLink to="/profile" class="text-capitalize dropdown-item">
              <i
                class="uil uil-setting fs-5 align-middle me-2"
                data-v-d1a7e442=""
              ></i>
              <span class="align-middle">{{ $trans("My Profile") }}</span>
            </RouterLink>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click.prevent="handleLogout"
              ><i
                class="uil uil-sign-out-alt fs-5 align-middle me-2"
                data-v-d1a7e442=""
              ></i>
              <span class="align-middle">{{ $trans("Logout") }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  computed: {
    user() {
      return this.$store.state.user || {};
    },
    notifications() {
      return this.user.alerts || [];
    },
    hasNotifications() {
      return (
        this.notifications.some((item) => item.read_at == null) &&
        !this.hasReadAll
      );
    },
  },
  methods: {
    handleLogout() {
      this.$http.get("auth/logout").then(() => {
        this.$auth.logout();
      });
    },
    readNotifications() {
      if (!this.hasReadAll) {
        this.$store.commit("SET_AJAX_TASK", "READING_NOTIFICATIONS");
        this.$http.get("auth/notifications/read").then(() => {
          this.hasReadAll = true;
        });
      }
    },
  },
};
</script>
